<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="300"
    style="z-index: 6"
  >
    <v-form ref="addCommForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Komunikacija</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0">
                  <v-select
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    label="Tip"
                    v-model="selectedCommType"
                    :items="availableComms"
                    item-text="text"
                    item-value="id"
                    return-object
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-2 pb-0 mb-0"> </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="
                      Vrijednost
                    "
                    v-model="value"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-select
                    v-if="selectedCommType !== undefined"
                    class="pt-0 mt-0 pb-0 mb-0"
                    outlined
                    dense
                    multiple
                    label="Koristi za"
                    v-model="selectedUsage"
                    :items="selectedCommType.available_usage"
                    item-text="text"
                    item-value="id"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ this.$t("$vuetify.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    type: undefined,
    selectedCommType: undefined,
    selectedUsage: [],
    value: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    availableComms: [],
    listeners: []
  }),
  mounted () {

  },
  methods: {
    open () {
      this.getCommTypes()
      this.visible = true
      this.submitting = false
      this.selectedCommType = undefined
    },
    getCommTypes () {
      const listener = df.doc('project_constants/communications').onSnapshot(doc => {
        this.availableComms = doc.data().types
      })
      this.listeners.push(listener)
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.type = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      const tblId = uuidv4()
      const docRef = df.doc(`company_communications/${state.getCurrentCompany().id}/communications/${tblId}`)

      if (!this.$refs.addCommForm.validate()) {
        return
      }

      var commParams = {
        id: tblId,
        type: this.selectedCommType.id,
        usage: this.selectedUsage,
        value: this.value
      }

      try {
        await docRef.set(
          commParams
        )
      } catch (err) {
        console.error('Error saving communication: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
