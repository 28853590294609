<template>
  <div style="width: 100%" class="fill-height">
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card
            v-for="(item, i) in sortedComms"
            :key="i"
            hover
            tabindex="0"
            focusable
          >
            <v-card-text>
              <v-row>
                <v-col cols="2" :class="[densityPadding]">
                  <div class="pl-1 caption grey--text">Tip</div>
                  <div class="pl-1 text-capitalize">{{ item.type }}</div>
                </v-col>
                <v-col cols="2" :class="[densityPadding]">
                  <div class="pl-1 caption grey--text">Vrijednost</div>
                  <div class="pl-1">{{ item.value }}</div>
                </v-col>
                <v-col cols="4" :class="[densityPadding]">
                  <div class="pl-1 caption grey--text">Koristi se za</div>
                  <div
                    v-html="showUsages(item.type, item.usage)"
                    class="pl-1"
                  ></div>
                </v-col>
                <v-col cols="4" :class="[densityPadding]">
                  <div class="d-flex align-end justify-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-0 pt-0"
                          :x-small="density === 'greater'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="openEditCommunication(item)"
                          @click="openEditCommunication(item)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Uredi</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <add-communication ref="addCommunication"></add-communication>
      <edit-communication ref="editCommunication"></edit-communication>
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import AddCommunication from '@/modules/point-of-sale/components/communication-configuration/AddCommunication'
import EditCommunication from '@/modules/point-of-sale/components/communication-configuration/EditCommunication'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  name: 'CommunicationConfiguration',
  mixins: [applicationSettings],
  components: { AddCommunication, EditCommunication, InfiniteLoading },
  data: () => ({
    location: undefined,
    comms: [],
    selectedComm: undefined,
    showPass: false,
    sortedComms: [],
    listeners: [],
    availableComms: []
  }),
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getCommTypes()
  },
  beforeDestroy () {
  },
  methods: {
    getCommTypes () {
      const listener = df.doc('project_constants/communications').onSnapshot(doc => {
        this.availableComms = doc.data().types
      })
      this.listeners.push(listener)
    },
    showUsages (type, usages) {
      var readableusage = ''
      if (usages && usages.length > 0) {
        usages.forEach((usage, key) => {
          this.availableComms.forEach(aC => {
            if (aC.id === type) {
              aC.available_usage.forEach(u => {
                if (u.id === usage) {
                  readableusage += u.text
                  readableusage += '<br/>'
                }
              })
            }
          })
        })

        return readableusage
      }
    },
    openAddCommunication () {
      this.$refs.addCommunication.open()
    },
    openEditCommunication (comm) {
      this.$refs.editCommunication.open(comm, this.availableComms)
    },
    modifyComms (changedComm) {
      let found = false
      this.comms.forEach((comm, key) => {
        if (comm.id === changedComm.id) {
          found = true
          this.comms[key] = changedComm
        }
      })

      if (!found) {
        this.comms.push(changedComm)
      }

      this.sortedComms = clone(this.comms)
    },
    infiniteHandler ($state) {
      if (state.getCurrentCompany().id !== '') {
        const query = df.collection(`company_communications/${state.getCurrentCompany().id}/communications`)
        const listener = query
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              this.modifyComms(rec.data())
            })
            $state.complete()
          })

        this.listeners.push(listener)
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style scoped>
.myTest >>> .vm--modal {
  top: 100px !important;
}
</style>
